import React from "react";
import { SEO, PageLayout } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class LiveMusicPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"live-music-page"} hero={null}>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={12} textAlign={"center"}>
              <gigwell-gigstream
                identity-id={"513889"}
                agency={"513889"}
                venues={"564867"}
                settings={"website"}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "Live Music" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
